<template>
  <div>
    <v-card class="pa-6 ma-0">
      <v-container class="pa-0">
        <v-row
          align-content="center"
          class="my-0"
        >
          <v-card-title class="pt-0 ttext-uppercase">
            {{ $t('sets.newSet') }}
          </v-card-title>
        </v-row>
        <v-divider class="mb-2" />
        <div
          class="my-4"
          :style="{
            display: 'grid',
            'grid-template-columns': 'repeat(4, minmax(23%, 1fr))',
            'grid-template-rows': 'repeat(3, 1fr)',
            gap: '1rem',
          }"
        >
          <button
            v-ripple
            type="button"
            class="courier-logo"
            style="height: 128px; justify-content: center"
            @click="selectSpeditor({ code: 'CustomShipment' })"
          >
            <v-icon
              size="34"
              color="primary lighten-1"
            >
              mdi-package-variant-closed
            </v-icon>
            {{ $t('courierSet.differentShippingMethod') }}
          </button>
          <button
            v-for="speditor in speditors"
            :key="$t(speditor.translationCode)"
            v-ripple
            type="button"
            class="courier-logo"
            @click="selectSpeditor(speditor)"
          >
            <v-img
              :src="logo(speditor.code)"
              contain
              max-height="100%"
              max-width="100%"
              class="center"
              style="position: absolute"
            />
          </button>
        </div>
      </v-container>
    </v-card>
    <v-dialog
      v-model="chargeInfoDialog.visible"
      width="650px"
      @click:outside="chargeInfoDialog.visible = false"
    >
      <AdditionalCharge
        @@add="selectSpeditor(speditor)"
        @@close="
          speditor = {};
          chargeInfoDialog.visible = false;
        "
      ></AdditionalCharge>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { speditors } from '@/constants/Speditors';
import Vue from 'vue';
import AdditionalCharge from './AdditionalCharge.vue';

export default Vue.extend({
  components: { AdditionalCharge },
  props: {
    couriers: { type: Array, default: () => [] },
  },

  data: () => ({
    speditors,
    speditor: {},
    chargeInfoDialog: {
      visible: false,
    },
  }),

  methods: {
    close() {
      this.$emit('@close');
    },

    logo(speditor) {
      const spedLogo = this.speditors.find(
        (x) => x.code.toLowerCase() === speditor.toLowerCase(),
      ).logo;
      const images = require.context('@/assets/images/logo/speditors/', false, /\.png$/);
      return images(`./${spedLogo}`);
    },

    selectSpeditor(speditor) {
      if (!this.couriers.includes(speditor.code) && !this.chargeInfoDialog.visible) {
        this.speditor = speditor;
        this.chargeInfoDialog.visible = true;
        return;
      }
      this.$router.push({
        name: `user-configuration-set-${speditor.code.toLowerCase()}-new`,
        params: {
          speditor: speditor.code,
        },
      });
      this.speditor = {};
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';

.courier-logo {
  border: 2px solid rgba(grey, 0.2);
  border-radius: 0.375rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
  &:hover,
  &:focus,
  &:focus-visible {
    border: 2px solid $primary-lighten1;
  }
}

.img-descripiton {
  z-index: 111;
  position: absolute;
  text-align: right;
  width: 100%;
  height: 100%;
  padding: 12px;
}
</style>
