import { createApiClient } from '@/services/_auth_interceptors';
import { createQuery } from '@/helpers/query.js';

const config = require('../../oidc/index');

const Api = createApiClient(config.oidcSettings.spedtionConfigurationURL);

export default {
  traverse(o, fn) {
    Object.keys(o).forEach((i) => {
      fn.apply(this, [i, o[i]]);
      if (o[i] !== null && typeof o[i] === 'object') {
        this.traverse(o[i], fn);
      }
    });
  },

  getAll(params) {
    const queryParams = params?.query || {};
    const query = createQuery(queryParams);
    return Api.get(`/DHL/configuration/all?${query}`);
  },

  async getConfigurationSet(courier, setId, userId, organizationId) {
    const response = await Api.get(
      `${courier}/configuration/${setId}?userId=${userId}&organizationId=${organizationId}`,
    );
    return response;
  },

  getMetadata(courier, service, organizationId) {
    return Api.get(`${courier}/configuration/services/${service}?organizationId=${organizationId}`);
  },

  createConfigurationSet(courier, setData, organizationId) {
    return Api.post(`${courier}/configuration?organizationId=${organizationId}`, setData);
  },

  updateConfigurationSet(courier, setData, organizationId) {
    this.traverse(setData, (v) => {
      if (typeof v === 'object' && v !== null) {
        if (v.entitymMarkedAsDeleted) {
          v.clientEntityState = 2;
        } else if (!v.id) {
          v.clientEntityState = 4; // added
        } else {
          v.clientEntityState = 3; // modified
        }
      }
    });

    return Api.put(
      `${courier}/configuration/${setData.id}?organizationId=${organizationId}`,
      setData,
    );
  },

  deleteConfigurationSet(courier, setData, organizationId) {
    return Api.delete(`${courier}/configuration?organizationId=${organizationId}`, {
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      data: setData,
    });
  },
};
